/***************************
 * = COULEURS
 ***************************/

$color-primary: black;
$color-primary-dark: black;
$color-primary-light: #1f1f1f;
$color-secondary: #d90036;
$color-secondary-dark: #c20735;
$color-secondary-light: #f41149;
$color-tertiary: #ff8c00;
$color-tertiary-dark: #e0800a;
$color-tertiary-light: #fba236;
$black: #000;
$grey-dark: #666666;
$grey-light: #efefef;
$grey-medium: #dadada;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%font-normal {
	font-family: "opensans", sans-serif;
}

%font-bold {
	font-family: "opensans-bold", sans-serif;
}

%text {
	@extend %font-normal;
	font-size: 1.4rem;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	color: $black;
}

%text-secondary {
	@extend %font-normal;
	font-size: 1.4rem;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	color: $grey-dark;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-tertiary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	text-transform: none;
	border-radius: 5px;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 5px;
$border-radius-big: 0;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$header-background-color: black;
$header-border-bottom: var(--color-gray-400, $grey-medium);
$header-menu-active-color: $black;
$header-text-color: white;
$header-text-transform: uppercase;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-tertiary;
$common-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

$badge-background-color: rgba(0, 0, 0, 0.7);

/***************************
 * = FORM
 ***************************/

$active-color: $color-tertiary;
$active-color-dark: $color-tertiary-dark;
$active-color-light: $color-tertiary-light;
$input-border-color: $grey-medium;
$input-border: 2px solid var(--color-active, $active-color);
$input-border-radius: 5px;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: none;
$radio-border-radius: $border-radius-small;
$button-primary-background-color: $color-tertiary;
$button-primary-background-color-dark: $color-tertiary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$product-deal-text-color: white;

$offer-mobile-background-color: $color-primary;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $grey-dark;
$bloc-contact-text-color: white;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $color-primary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $black;
$booking-item-margin: 15px;
$booking-badge-background-color: $color-secondary;

$quotation-price-detail-toggle-button-background: $grey-dark;
$quotation-price-detail-total-background: $grey-dark;
$quotation-price-detail-total-border: 1px solid $grey-dark;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;
$quotation-box-shadow: none;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: rgba(0, 0, 0, 0.7);
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: $border-radius-small;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-small;
$flight-filter-background-color: $active-color;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: #8c8c8c;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-tertiary;

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: inherit;
$product-deal-public-price-line-through-color: $black;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$colored-product-badge-background-color: white;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: $border-radius-small;
$merchandising-simple-width: 100%;
$listing-time-background-color: rgba(0, 0, 0, 0.7);

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: var(--color-active, $active-color);
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$auth-background-color: #27c8ee;
$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-af.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-slide-small-af-1.svg");
